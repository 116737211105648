/* eslint-disable camelcase */
import dashboard from './dashboard';
import manage_users from './manage_user';
import manage_channels from './manage_channels';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, manage_channels, manage_users]
};

export default menuItems;
