/* eslint-disable import/extensions */
import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'components/layout/MinimalLayout';

// login option 3 routing
// const LoginPage = Loadable(lazy(() => import('pages/auth/login')));
const RegisterPage = Loadable(lazy(() => import('pages/auth/register')));
const LoginPage = Loadable(lazy(() => import('pages/auth/Login/index.jsx')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/auth/login',
            element: <LoginPage />
        },
        {
            path: '/auth/register',
            element: <RegisterPage />
        }
    ]
};

export default AuthenticationRoutes;
