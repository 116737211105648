// assets
import { IconBrandTiktok, IconBrandYoutube, IconSocial, IconCategory, IconTrademark } from '@tabler/icons';
// constant
const icons = { IconBrandYoutube, IconBrandTiktok, IconSocial, IconCategory, IconTrademark };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

// eslint-disable-next-line camelcase
const manage_channels = {
    id: 'manage-channels',
    title: 'Manage Channels',
    type: 'group',
    children: [
        {
            id: 'channels',
            title: 'Channels',
            type: 'item',
            url: '/channels',
            icon: icons.IconSocial,
            breadcrumbs: false
        },
        {
            id: 'videos',
            title: 'Videos',
            type: 'item',
            url: '/videos',
            icon: icons.IconBrandYoutube,
            breadcrumbs: false
        },
        {
            id: 'groups',
            title: 'Groups',
            type: 'item',
            url: '/groups',
            icon: icons.IconTrademark,
            breadcrumbs: false
        },
        {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: '/categories',
            icon: icons.IconCategory,
            breadcrumbs: false
        }
    ]
};

// eslint-disable-next-line camelcase
export default manage_channels;
