// assets
import { IconDashboard, IconUserCheck, IconUsers, IconLicense } from '@tabler/icons';
// constant
const icons = { IconDashboard, IconUserCheck, IconUsers, IconLicense };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// eslint-disable-next-line camelcase
const manage_users = {
    id: 'manage-users',
    title: 'Manage Users',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/manage-users/users',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'role',
            title: 'Role',
            type: 'item',
            url: '/manage-users/role',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

// eslint-disable-next-line camelcase
export default manage_users;
