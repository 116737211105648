import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'components/layout/MainLayout';
import Loadable from 'components/Loadable';

// dashboard routing
const DashboardAdmin = Loadable(lazy(() => import('pages/dashboard/admin')));
const DashboardChanel = Loadable(lazy(() => import('pages/dashboard/chanel')));

// manage-users
const Users = Loadable(lazy(() => import('pages/manage_user/users')));
const Role = Loadable(lazy(() => import('pages/manage_user/role')));

// manage-channels
const Channels = Loadable(lazy(() => import('pages/manage_channel/channels')));
const Videos = Loadable(lazy(() => import('pages/manage_channel/videos')));
const Categories = Loadable(lazy(() => import('pages/manage_channel/categories')));
const Groups = Loadable(lazy(() => import('pages/manage_channel/groups')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to="/dashboard" replace />
        },
        {
            path: '/dashboard',
            element: <DashboardAdmin />
        },
        {
            path: '/channels/:platform/:channel_id',
            element: <DashboardChanel />
        },
        // manage users
        {
            path: '/manage-users/users',
            element: <Users />
        },
        {
            path: '/manage-users/role',
            element: <Role />
        },
        // manage channels
        {
            path: '/videos',
            element: <Videos />
        },
        {
            path: '/channels',
            element: <Channels />
        },
        {
            path: '/categories',
            element: <Categories />
        },
        {
            path: '/groups',
            element: <Groups />
        }
    ]
};

export default MainRoutes;
