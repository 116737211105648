import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

// toastify
import { ToastContainer } from 'react-toastify';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'components/layout/NavigationScroll';
import SetupInterceptors from 'services/interceptor';

// ==============================|| APP ||============================== //

const App = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const customization = useSelector((state) => state.customization);
    if (!isLoaded) {
        setIsLoaded(true);
        SetupInterceptors(navigate);
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
