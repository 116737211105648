import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import logoIMG from 'assets/images/logo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo /> */}
        <img style={{ width: '150px', height: '100%' }} src={logoIMG} alt="logo" />
    </ButtonBase>
);

export default LogoSection;
