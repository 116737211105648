// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDeviceAnalytics };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        }
        // {
        //     id: 'chanel',
        //     title: 'Dashboard Chanel',
        //     type: 'item',
        //     url: '/dashboard/chanel',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
    ]
};

export default dashboard;
